'use client';
import { ReactNode } from 'react';

import { Contentful } from '@pbx/shared/contentful/providers';
import { SkipToContent } from '@pbx/shared/ui/layout/skip-to-content';

export function DefaultLayout({ children }: { children: ReactNode }) {
  return (
    <div className="bg-background-primary grid min-h-full grid-rows-[auto_1fr_auto]">
      <SkipToContent skipToId="main-content" />
      <Contentful.Header />
      <div id="main-content" className="overflow-hidden">
        {children}
      </div>
      <Contentful.Footer />
    </div>
  );
}
