'use client';
import { FC } from 'react';

import { useContentfulComponents } from '../contentful-content-provider/contentful-content-provider';
import {
  componentNames,
  TComponentDictionary,
  TComponentProps,
  TComponentRendererProps,
  TComponentsTypesMap,
} from '../contentful-content-provider/contentful-content-provider.types';

export const ComponentRenderer: FC<TComponentRendererProps> = ({
  componentType,
  entryId,
  ...rest
}) => {
  const components = useContentfulComponents();

  const [Component, componentProps = {}] = components?.[componentType] || [];

  const props = entryId
    ? componentProps[entryId]
    : componentProps[Object?.keys(componentProps)[0]];

  return Component ? <Component {...props} {...rest} /> : null;
};

const ComponentsTypesMap: TComponentsTypesMap = {
  Header: 'headerPrimary',
  HeaderMortgages: 'headerMortgages',
  Footer: 'footerPrimary',
};

export const COMPONENT_DICTIONARY: TComponentDictionary = componentNames.reduce(
  (dictionary, currentName) => {
    const componentType = ComponentsTypesMap[currentName];

    return Object.assign(dictionary, {
      [currentName]: (params: TComponentProps) => (
        <ComponentRenderer {...params} componentType={componentType} />
      ),
    });
  },
  {} as TComponentDictionary
);
