import { PropsWithChildren, ReactNode } from 'react';
import { FieldsType } from 'contentful/dist/types/types/query/util';

export type TCMSComponentMap = Record<TSharedComponent, any>;

export type TCMSComponentsProps = {
  components: any;
  componentsMap: TCMSComponentMap;
};

export type TSharedComponent =
  | 'headerPrimary'
  | 'footerPrimary'
  | 'headerMortgages';

export type TCMSComponentProviderValue = {
  [key in TSharedComponent]: [React.FunctionComponent, any];
};

export type TComponentProps = {
  entryId?: string;
} & Record<string, any>;

export type TComponentRendererProps = TComponentProps & {
  componentType: TSharedComponent;
};

export const componentNames = ['Header', 'Footer', 'HeaderMortgages'] as const;
export type TComponentNames = (typeof componentNames)[number];

export type TComponentsTypesMap = {
  [key in TComponentNames]: TSharedComponent;
};

export type TComponentDictionary = Record<
  TComponentNames,
  (params: TComponentProps) => ReactNode
>;

export type TComponentWrapperProps = PropsWithChildren<{
  componentMap: TCMSComponentMap;
}>;

export type TTransformedComponents = {
  [key in TSharedComponent]: Record<string, FieldsType>;
};
