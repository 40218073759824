'use client';
import {
  PropsWithChildren,
  ReactElement,
  createContext,
  useContext,
} from 'react';

import {
  TCMSComponentMap,
  TCMSComponentProviderValue,
  TCMSComponentsProps,
  TSharedComponent,
} from './contentful-content-provider.types';

export function useContentfulComponents() {
  return useContext(ContentfulContentContext);
}

export const ContentfulContentContext = createContext<
  TCMSComponentProviderValue | undefined
>(undefined);

export function ContentfulContentProvider({
  children,
  components,
  componentsMap,
}: PropsWithChildren<TCMSComponentsProps>): ReactElement {
  const providerValue = createProviderValue(components, componentsMap);

  return (
    <ContentfulContentContext.Provider value={providerValue}>
      {children}
    </ContentfulContentContext.Provider>
  );
}

export function createProviderValue(
  components: any,
  componentsMap: TCMSComponentMap
) {
  return Object.keys(componentsMap).reduce((result, value) => {
    return {
      ...result,
      [value]: [componentsMap[value as TSharedComponent], components[value]],
    };
  }, {} as TCMSComponentProviderValue);
}
