import { useEffect, useState } from 'react';

import { Text } from '@pbx/shared/ui/common/text';

import { TEventTarget, TSkipToContent } from './skip-to-content.types';

export function SkipToContent({ skipToId = 'main-content' }: TSkipToContent) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleKeyPress: EventListener = (event: Event) => {
      const keyboardEvent = event as KeyboardEvent;
      const eventTarget = keyboardEvent.target as TEventTarget;

      if (!eventTarget.form && keyboardEvent.key === 'Tab') {
        event.preventDefault();
        setIsVisible(true);
        window.removeEventListener('keydown', handleKeyPress);
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const scrollToContent = () => {
    const contentAnchor = document.getElementById(skipToId);
    if (contentAnchor) {
      contentAnchor.scrollIntoView({ behavior: 'smooth' });
    }
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className="animate-slideDownAndFade bg-button-primary-background hover:bg-button-primary-background-hover focus-visible:border-focus w-full cursor-pointer px-4 py-3 text-center transition-colors hover:underline focus:outline-none"
      onClick={scrollToContent}
    >
      <Text variant="ui" size="medium-500" className="text-foreground-contrast">
        Skip to content
      </Text>
    </div>
  );
}
